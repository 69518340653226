import React, { useState } from "react";
import { AppLayout } from "../shared/layout";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { getProfile, changePassword } from "../../../data";
import { useForm } from "react-hook-form";
import { Button } from "@/components/ui/button";
import { toast } from "@/components/ui/use-toast";
import { Card, CardHeader, CardTitle, CardContent } from "@/components/ui/card";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { EyeIcon, EyeOffIcon } from "lucide-react";

export function ProfileScreen() {
  const queryClient = useQueryClient();
  const { data, isLoading, error } = useQuery({
    queryKey: ["profile"],
    queryFn: getProfile,
  });

  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const changePasswordMutation = useMutation({
    mutationFn: changePassword,
    onSuccess: () => {
      toast({
        title: "Password changed successfully",
        type: "success",
      });
      reset();
    },
    onError: (error) => {
      toast({
        title: "Error changing password",
        description: error.message,
        type: "error",
      });
    },
  });

  const onSubmit = (formData) => {
    changePasswordMutation.mutate(formData);
  };

  const pageSettings = {
    title: "Profile",
    actions: [],
  };

  return (
    <AppLayout
      title={pageSettings.title}
      actions={pageSettings.actions}
      loading={isLoading}
    >
      {error ? (
        <div>Bu alani gorme yetkiniz yok</div>
      ) : (
        !isLoading && (
          <div className="space-y-8">
            <Card>
              <CardHeader>
                <CardTitle>User Information</CardTitle>
              </CardHeader>
              <CardContent className="space-y-2">
                <div>
                  <Label className="font-semibold">Name: </Label>
                  <span>{data.data.user.name}</span>
                </div>
                <div>
                  <Label className="font-semibold">Email: </Label>
                  <span>{data.data.user.email}</span>
                </div>
                <div>
                  <Label className="font-semibold">Created At: </Label>
                  <span>{data.data.user.createdAt}</span>
                </div>
              </CardContent>
            </Card>

            <Card>
              <CardHeader>
                <CardTitle>Change Password</CardTitle>
              </CardHeader>
              <CardContent>
                <form onSubmit={handleSubmit(onSubmit)} className="space-y-4">
                  <div className="space-y-2">
                    <Label htmlFor="currentPassword">Current Password</Label>
                    <div className="relative">
                      <Input
                        type={showCurrentPassword ? "text" : "password"}
                        id="currentPassword"
                        {...register("currentPassword", {
                          required: "Current password is required",
                        })}
                      />
                      <button
                        type="button"
                        onClick={() =>
                          setShowCurrentPassword(!showCurrentPassword)
                        }
                        className="absolute right-2 top-1/2 transform -translate-y-1/2"
                      >
                        {showCurrentPassword ? (
                          <EyeOffIcon className="h-4 w-4 text-gray-500" />
                        ) : (
                          <EyeIcon className="h-4 w-4 text-gray-500" />
                        )}
                      </button>
                    </div>
                    {errors.currentPassword && (
                      <p className="text-red-500">
                        {errors.currentPassword.message}
                      </p>
                    )}
                  </div>
                  <div className="space-y-2">
                    <Label htmlFor="newPassword">New Password</Label>
                    <div className="relative">
                      <Input
                        type={showNewPassword ? "text" : "password"}
                        id="newPassword"
                        {...register("newPassword", {
                          required: "New password is required",
                          minLength: {
                            value: 8,
                            message:
                              "Password must be at least 8 characters long",
                          },
                        })}
                      />
                      <button
                        type="button"
                        onClick={() => setShowNewPassword(!showNewPassword)}
                        className="absolute right-2 top-1/2 transform -translate-y-1/2"
                      >
                        {showNewPassword ? (
                          <EyeOffIcon className="h-4 w-4 text-gray-500" />
                        ) : (
                          <EyeIcon className="h-4 w-4 text-gray-500" />
                        )}
                      </button>
                    </div>
                    {errors.newPassword && (
                      <p className="text-red-500">
                        {errors.newPassword.message}
                      </p>
                    )}
                  </div>
                  <div className="space-y-2">
                    <Label htmlFor="confirmPassword">
                      Confirm New Password
                    </Label>
                    <div className="relative">
                      <Input
                        type={showConfirmPassword ? "text" : "password"}
                        id="confirmPassword"
                        {...register("confirmPassword", {
                          required: "Please confirm your new password",
                          validate: (value) =>
                            value ===
                              document.getElementById("newPassword").value ||
                            "Passwords do not match",
                        })}
                      />
                      <button
                        type="button"
                        onClick={() =>
                          setShowConfirmPassword(!showConfirmPassword)
                        }
                        className="absolute right-2 top-1/2 transform -translate-y-1/2"
                      >
                        {showConfirmPassword ? (
                          <EyeOffIcon className="h-4 w-4 text-gray-500" />
                        ) : (
                          <EyeIcon className="h-4 w-4 text-gray-500" />
                        )}
                      </button>
                    </div>
                    {errors.confirmPassword && (
                      <p className="text-red-500">
                        {errors.confirmPassword.message}
                      </p>
                    )}
                  </div>
                  <Button
                    type="submit"
                    disabled={changePasswordMutation.isLoading}
                  >
                    {changePasswordMutation.isLoading
                      ? "Changing..."
                      : "Change Password"}
                  </Button>
                </form>
              </CardContent>
            </Card>
          </div>
        )
      )}
    </AppLayout>
  );
}
