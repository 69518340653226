import api from "../api";

export const getClients = (data) => {
  return api.getApi().get(api.getBaseUrl() + "clients", {
    params: {
      page: data.page,
      pageSize: data.pageSize,
      nameFilter: data.nameFilter,
      countryFilter: data.countryFilter,
      lucaIdFilter: data.lucaIdFilter,
      sortColumn: data.sortColumn,
      sortDirection: data.sortDirection,
      emailFilter: data.emailFilter,
    },
  });
};
export const getClient = (id) => {
  return api.getApi().get(api.getBaseUrl() + "clients/" + id);
};
export const createClient = (data) => {
  return api.getApi().post(api.getBaseUrl() + "clients", data);
};

export const updateClient = (id, data) => {
  console.log("data", id);
  return api.getApi().put(api.getBaseUrl() + "clients/" + id, data);
};
export const getProfile = () => {
  return api.getApi().get(api.getBaseUrl() + "profile");
};

export const deleteClient = (id) => {
  return api.getApi().delete(api.getBaseUrl() + "clients/" + id);
};
export const uploadClientWithExcel = (data) => {
  return api.getApi().post(api.getBaseUrl() + "clients/uploadExcel", data);
};

export const downloadClientsWithNullLucaId = () => {
  return api
    .getApiForBlob()
    .get(api.getBaseUrl() + "excels/download-null-lucaid");
};

export const uploadClientsLucaInfo = (data) => {
  return api.getApi().post(api.getBaseUrl() + "excels/upload-luca-info", data);
};

export const getClientsByEmail = (email) => {
  return api
    .getApi()
    .get(api.getBaseUrl() + "by-email/" + email)
    .then((res) => res.data);
};
