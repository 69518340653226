import React, { useEffect } from "react";
import AuthStore from "../../../stores/AuthStore";
import { AppLayout, DashboardLayout } from "../shared/layout";
import { Link } from "react-router-dom";
import { Button } from "@/components/ui/button";

export function HomeScreen() {
  useEffect(() => {});
  return (
    <div>
      <AppLayout title="Dashboard">
        <Button asChild className="mt-4 h-64 w-64">
          <Link to="/invoices-all">All Invoices</Link>
        </Button>
      </AppLayout>
    </div>
  );
}
