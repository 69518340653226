import { data } from "autoprefixer";
import api from "../api";

export const getInvoices = (id, data) => {
  return api.getApi().get(api.getBaseUrl() + "payments/" + id + "/invoices", {
    params: {
      page: data.page,
      pageSize: data.pageSize,
      nameFilter: data.nameFilter,
      emailNameFilter: data.emailNameFilter,
      statusFilter: data.statusFilter,
      lucaIdFilter: data.lucaIdFilter,
    },
  });
};
export const getAllInvoices = (data) => {
  return api.getApi().get(api.getBaseUrl() + "invoices-all", {
    params: {
      page: data.page,
      pageSize: data.pageSize,
      nameFilter: data.nameFilter,
      emailNameFilter: data.emailNameFilter,
      statusFilter: data.statusFilter,
      lucaIdFilter: data.lucaIdFilter,
    },
  });
};
export const getInvoice = (id, commissionDetailId) => {
  return api
    .getApi()
    .get(
      api.getBaseUrl() + "payments/" + id + "/invoices/" + commissionDetailId
    );
};
export const updateInvoice = (id, data) => {
  return api.getApi().put(api.getBaseUrl() + "invoices/" + id, data);
};

export const approveInvoice = (data) => {
  console.log("data", data);
  console.log("data.id", data.id);
  return api
    .getApi()
    .put(api.getBaseUrl() + `invoices/${data.id}/approve`, { data: data.data });
};

export const downloadInvoicesExcel = (id) => {
  console.log("id", id);
  return api
    .getApiForBlob()
    .get(api.getBaseUrl() + "payments/" + id + "/invoices/downloadAll");
};

export const downloadFilteredInvoicesExcel = (id, params) => {
  return api.getApi().get(api.getBaseUrl() + `payments/${id}/excelDownload`, {
    params,
    responseType: "blob",
  });
};
export const downloadFilteredInvoicesAllExcel = (params) => {
  return api.getApi().get(api.getBaseUrl() + `invoices-all/excelDownload`, {
    params,
    responseType: "blob",
  });
};
export const uploadInvoiceStatusExcel = (id, data) => {
  return api
    .getApi()
    .post(api.getBaseUrl() + `excels/invoicesUploadStatus`, data);
};
