import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import {
  createClient,
  getClientsByEmail,
  getInvoice,
  getPayment,
} from "../../../data";

import { Link, useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { AppLayout } from "../shared/layout";
import { Button } from "@/components/ui/button";
import { Document, Page } from "react-pdf";
import { Badge } from "@/components/ui/badge";
import { Label } from "@/components/ui/label";
import { ClientViewDialog, InvoiceDetailItem } from "@/components";
import { approveInvoice } from "@/data/requests/invoice-requests";
import { toast } from "@/components/ui/use-toast";
import PDFViewerCustom from "@/components/pdf-viewer";
import { useEffect, useRef, useState } from "react";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { Input } from "@/components/ui/input";
import ReactQuill from "react-quill";

export function InvoiceDetailScreen() {
  const [clientsWithSameEmail, setClientsWithSameEmail] = useState([]);
  const queryClient = useQueryClient();
  const [isClientViewOpen, setIsClientViewOpen] = useState(false);

  const { selectedGpt, selectedGptId } = useState("");

  const formButtonRef = useRef(null);
  const { id, invoiceId } = useParams();
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    getValues,
    formState: { errors },
  } = useForm();

  const mutation = useMutation({
    mutationKey: ["approveInvoice"],
    mutationFn: (data) => {
      return approveInvoice(data);
    },
    onSuccess: async (response) => {
      queryClient.invalidateQueries(["invoices", id]);

      if (response.data.message == "Mail Sent") {
        toast({
          title: "Mail Sended ",
          message: "The invoice has been approved successfully.",
        });
        navigate(`/payments/${id}/invoices`);
      }
      if (response.data.status === "Approved") {
        toast({
          title: "Invoice Approved",
          message: "The invoice has been approved successfully.",
        });
      }
    },
    onError: (error) => {
      toast({
        title: error.response.data.message,
        type: "error",
      });
    },
  });
  const createClientMutation = useMutation({
    mutationKey: ["createClient"],
    mutationFn: (data) => {
      return createClient(data);
    },
    onSuccess: (response) => {
      queryClient.invalidateQueries(["invoiceDetail", invoiceId]);
      toast({
        title: "Client Created",
        message: "The client has been created successfully.",
      });
      onAccept("manuelClientSearch");
    },
    onError: (error) => {
      toast({
        title: error.response.data.message,
        type: "error",
      });
    },
  });
  const handleCreateClient = () => {
    const clientData = {
      name: data.data.commissionDetail.commission.influencerName,
      email: data.data.commissionDetail.commission.email,
      IBAN: data.data.commissionDetail.iban,
      SWIFT: data.data.commissionDetail.bic,
      billingName: data.data.commissionDetail.bankAccountHolder,
      bankName: data.data.commissionDetail.bankName,
      country: data.data.commissionDetail.commission.country,
    };
    createClientMutation.mutate(clientData);
  };
  const pageSettings = {
    title: "Invoice Detail",
    actions: [
      {
        name: "Go To List",
        color: "blue",
        icon: "add-icon",
        onClick: () => {
          navigate(`/payments/${id}/invoices`);
        },
      },
      {
        name: "Mark As Troubled",
        color: "bg-blue-500 text-white",
        icon: "add-icon",
        onClick: () => {
          if (!isCanEdit) {
            toast({
              variant: "destructive",
              title: "You can not edit this invoice",
              description: "This invoice is already paid",
              status: "error",
            });
            return;
          }

          mutation.mutate({
            id: invoiceId,
            data: {
              status: "Troubled",
            },
          });
        },
      },
      {
        name: "Accounting",
        color: "bg-purple-700 text-white",
        icon: "add-icon",
        onClick: () => {
          if (!isCanEdit) {
            toast({
              variant: "destructive",
              title: "You can not edit this invoice",
              description: "This invoice is already paid",
              status: "error",
            });
            return;
          }

          mutation.mutate({
            id: invoiceId,
            data: {
              status: "Accounting",
            },
          });
        },
      },
      ,
      {
        name: "LUCA",
        color: "bg-red-500 text-white",
        icon: "add-icon",
        onClick: () => {
          if (!isCanEdit) {
            toast({
              variant: "destructive",
              title: "You can not edit this invoice",
              description: "This invoice is already paid",
              status: "error",
            });
            return;
          }

          mutation.mutate({
            id: invoiceId,
            data: {
              status: "LUCA",
            },
          });
        },
      },
      {
        name: "Paid",
        color: "bg-green-500 text-white",
        icon: "add-icon",
        onClick: () => {
          if (!isCanEdit) {
            toast({
              variant: "destructive",
              title: "You can not edit this invoice",
              description: "This invoice is already paid",
              status: "error",
            });
            return;
          }

          mutation.mutate({
            id: invoiceId,
            data: {
              status: "Paid",
            },
          });
        },
      },

      {
        name: "Approve",
        color: "bg-blue-700 text-white",
        icon: "add-icon",
        onClick: () => {
          if (!isCanEdit) {
            toast({
              variant: "destructive",
              title: "You can not edit this invoice",
              description: "This invoice is already paid",
              status: "error",
            });
            return;
          }

          mutation.mutate({
            id: invoiceId,
            data: {
              status: "Approved",
            },
          });
        },
      },
      {
        name: "Dowload Pdf",
        color: "bg-pink-500 text-white",
        icon: "add-icon",
        onClick: () => {
          var link =
            "https://fantaslinkworld.blob.core.windows.net/invoicememberuploads/" +
            data.data.commissionDetail.invoicePath.replace(
              "public/uploads/paymentPdfs/",
              ""
            );

          window.open(link, "_blank");
        },
      },

      {
        name: "Save",
        color: "bg-gray-500 text-white",
        icon: "add-icon",
        onClick: () => {
          if (!isCanEdit) {
            toast({
              variant: "destructive",
              title: "You can not edit this invoice",
              description: "This invoice is already paid",
              status: "error",
            });
            return;
          }

          formButtonRef.current.click();
        },
      },
    ],
  };

  const { data, isLoading, error } = useQuery({
    queryKey: ["invoiceDetail", invoiceId],
    queryFn: async () => await getInvoice(id, invoiceId),
  });
  const isCanEdit = data?.data?.commissionDetail?.status != "Paid";
  useEffect(() => {
    if (data?.data?.commissionDetail?.commission?.email) {
      getClientsByEmail(data.data.commissionDetail.commission.email).then(
        (res) => {
          setClientsWithSameEmail(res);
        }
      );
    }
  }, [data]);

  const onSubmit = async (data) => {
    console.log("data form", data);
    mutation.mutate({ id: invoiceId, data: data });
  };

  const sendChangeRequest = async () => {
    if (!isCanEdit) {
      toast({
        variant: "destructive",
        title: "You can not edit this invoice",
        description: "This invoice is already paid",
        status: "error",
      });
      return;
    }
    console.log("sendChangeRequest");
    mutation.mutate({
      id: invoiceId,
      data: {
        sendMailAction: true,
        mailBodyMessage: getValues("mailBodyMessage"),
      },
    });
  };
  const handleClientChange = async (clientId) => {
    try {
      await mutation.mutateAsync({
        id: invoiceId,
        data: {
          clientId: parseInt(clientId),
        },
      });
      queryClient.invalidateQueries(["invoiceDetail", invoiceId]);
    } catch (error) {
      console.error("Error changing client:", error);
      toast({
        title: "Error changing client",
        description: error.message,
        status: "error",
      });
    }
  };

  const onAccept = (field) => {
    if (!isCanEdit) {
      toast({
        variant: "destructive",
        title: "You can not edit this invoice",
        description: "This invoice is already paid",
        status: "error",
      });
      return;
    }

    switch (field) {
      case "isInvoiceIdApproved":
        mutation.mutate({
          id: invoiceId,
          data: {
            isInvoiceIdApproved: true,
          },
        });
        break;
      case "isEmailApproved":
        mutation.mutate({
          id: invoiceId,
          data: {
            isEmailApproved: true,
          },
        });
        break;
      case "isTotalAmountApproved":
        mutation.mutate({
          id: invoiceId,
          data: {
            isTotalAmountApproved: true,
          },
        });
        break;
      case "isIbanApproved":
        mutation.mutate({
          id: invoiceId,
          data: {
            isIbanApproved: true,
          },
        });
        break;
      case "isBicApproved":
        mutation.mutate({
          id: invoiceId,
          data: {
            isBicApproved: true,
          },
        });
        break;
      case "isInvoiceDateApproved":
        mutation.mutate({
          id: invoiceId,
          data: {
            isInvoiceDateApproved: true,
          },
        });
        break;
      case "isBankAccountHolderApproved":
        mutation.mutate({
          id: invoiceId,
          data: {
            isBankAccountHolderApproved: true,
          },
        });
        break;
      case "isOurCompanyApproved":
        mutation.mutate({
          id: invoiceId,
          data: {
            isOurCompanyApproved: true,
          },
        });
        break;
      case "manuelClientSearch":
        mutation.mutate({
          id: invoiceId,
          data: {
            manuelClientSearch: true,
          },
        });
        break;
    }
  };

  if (isLoading) {
    return (
      <AppLayout>
        <div>Loading...</div>
      </AppLayout>
    );
  }

  const getLucaIdMatch = () => {
    console.log(
      data?.data?.commissionDetail?.commission?.client?.lucaAccountName?.toLocaleUpperCase(
        "tr-TR"
      ),
      data?.data?.commissionDetail?.bankAccountHolder?.toLocaleUpperCase()
    );
    return (
      data?.data?.commissionDetail?.commission?.client?.lucaAccountName?.toLocaleUpperCase() ==
        data?.data?.commissionDetail?.bankAccountHolder?.toLocaleUpperCase() ||
      data?.data?.commissionDetail?.commission?.client?.lucaAccountName?.toLocaleLowerCase() ==
        data?.data?.commissionDetail?.bankAccountHolder?.toLocaleLowerCase() ||
      data?.data?.commissionDetail?.commission?.client?.lucaAccountName?.toLocaleUpperCase(
        "tr-TR"
      ) ==
        data?.data?.commissionDetail?.bankAccountHolder?.toLocaleUpperCase(
          "tr-TR"
        ) ||
      data?.data?.commissionDetail?.commission?.client?.lucaAccountName?.toLocaleLowerCase(
        "tr-TR"
      ) ==
        data?.data?.commissionDetail?.bankAccountHolder?.toLocaleLowerCase(
          "tr-TR"
        )
    );
  };
  return (
    <AppLayout
      fullscreen={true}
      title={pageSettings.title + " - " + data?.data?.commissionDetail?.id}
      actions={pageSettings.actions}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="flex flex-row gap-3 justify-between">
          {data.data.commissionDetail.commission.client == null ? (
            <div className="flex flex-1 flex-col gap-2 mb-3 bg-orange-400 p-3 rounded-md text-white">
              <h1>
                User Not Found. Please click button to search user manually.
              </h1>
              <div className="flex flex-row gap-2">
                <Button
                  onClick={() => {
                    onAccept("manuelClientSearch");
                  }}
                >
                  Find User And Connect With Commission
                </Button>
                <Button
                  style={{ backgroundColor: "#2463EB", color: "white" }}
                  onClick={handleCreateClient}
                  disabled={createClientMutation.isLoading}
                >
                  Create Automatically New Client
                </Button>
              </div>
            </div>
          ) : (
            <div className="flex flex-1 flex-col gap-2 mb-3 bg-orange-400 p-3 rounded-md text-white"></div>
          )}
          <div className="flex flex-1 items-center justify-between gap-3 bg-blue-500 p-3 rounded-md mb-3">
            <div className="flex items-center gap-2">
              <h1 className="text-white">OCR AGAIN </h1>
              <Select value={selectedGpt} onValueChange={selectedGptId}>
                <SelectTrigger className="w-[300px]">
                  <SelectValue placeholder="Select Gpt Prompt" />
                </SelectTrigger>
                <SelectContent>
                  <SelectGroup>
                    <SelectLabel>Gpt Prompts </SelectLabel>

                    {data.data.gptPrompts.map((prompt) => (
                      <SelectItem value={prompt.id.toString()} key={prompt.id}>
                        {prompt.name}
                      </SelectItem>
                    ))}
                  </SelectGroup>
                </SelectContent>
              </Select>
              <Button
                type="button"
                onClick={() => {
                  if (!isCanEdit) {
                    toast({
                      variant: "destructive",
                      title: "You can not edit this invoice",
                      description: "This invoice is already paid",
                      status: "error",
                    });
                    return;
                  }
                  mutation.mutate({
                    id: invoiceId,
                    data: {
                      gptPrompt: selectedGptId,
                    },
                  });
                }}
              >
                OCR AGAIN
              </Button>
            </div>
            <div>
              <Dialog>
                <DialogTrigger asChild>
                  <Button variant="destructive">Send Change Request</Button>
                </DialogTrigger>
                <DialogContent className="sm:max-w-[425px]">
                  <DialogHeader>
                    <DialogTitle>Send Change Request</DialogTitle>
                    <DialogDescription>
                      Write a message to the client to inform them about the
                      change request.
                    </DialogDescription>
                  </DialogHeader>
                  <div className="grid gap-4 py-4">
                    <div className="grid  items-center">
                      <Label htmlFor="name" className="text-right">
                        Message
                      </Label>
                      <ReactQuill
                        theme="snow"
                        onChange={(value) => setValue("mailBodyMessage", value)}
                      />
                    </div>
                  </div>
                  <DialogFooter>
                    <Button type="button" onClick={() => sendChangeRequest()}>
                      Send Mail
                    </Button>
                  </DialogFooter>
                </DialogContent>
              </Dialog>
            </div>
          </div>
        </div>
        <div className="flex flex-row gap-3 justify-between">
          {data?.data?.commissionDetail?.commission.client ? (
            <>
              {!getLucaIdMatch() ? (
                <div className="flex flex-1 items-center justify-between gap-3 bg-red-500 p-3 rounded-md mb-3 text-white">
                  <h1>
                    Luca Account Name Not Match, Please Update Luca Account Name
                    or Create New Client
                  </h1>
                  <div className="flex flex-row gap-2">
                    <Select
                      value={data.data.commissionDetail.commission.client.id.toString()}
                      onValueChange={(value) => handleClientChange(value)}
                    >
                      <SelectTrigger className="w-[300px] text-foreground">
                        <SelectValue className="text-black">
                          {data.data.commissionDetail.commission.client.name} -
                          (
                          {
                            data.data.commissionDetail.commission.client
                              .lucaAccountName
                          }
                          )
                        </SelectValue>
                      </SelectTrigger>
                      <SelectContent>
                        {clientsWithSameEmail.map((client) => (
                          <SelectItem
                            key={client.id}
                            value={client.id.toString()}
                          >
                            {client.name} - ({client.lucaAccountName})
                          </SelectItem>
                        ))}
                      </SelectContent>
                    </Select>
                    <Button
                      variant="secondary"
                      onClick={handleCreateClient}
                      disabled={createClientMutation.isLoading}
                    >
                      Create Connected Client
                    </Button>
                  </div>
                </div>
              ) : (
                <div className="flex flex-1 items-center justify-between gap-3 bg-green-500 p-3 rounded-md mb-3 text-white">
                  <h1>Luca ID Match</h1>
                </div>
              )}
            </>
          ) : (
            <div className="flex flex-1 items-center justify-between gap-3  p-3 rounded-md mb-3 text-white">
              <h1>User Not Found</h1>
            </div>
          )}
          <div className="flex flex-1 items-center justify-between gap-3  bg-purple-500 p-3 rounded-md mb-3">
            {data.data.commissionDetail.commission.client && (
              <div className="flex flex-row gap-2">
                <Button
                  variant="secondary"
                  onClick={() => setIsClientViewOpen(true)}
                >
                  View Client Card
                </Button>
                <Button
                  variant="secondary"
                  onClick={() =>
                    navigate(
                      `/clients/${data.data.commissionDetail.commission.client.id}`
                    )
                  }
                >
                  Go Edit Client
                </Button>
              </div>
            )}
            <ClientViewDialog
              isOpen={isClientViewOpen}
              onClose={() => setIsClientViewOpen(false)}
              clientId={data?.data?.commissionDetail?.commission?.client?.id}
            />
          </div>
        </div>
        <div>
          <div className="flex">
            <div className="flex flex-col w-1/3 gap-1">
              <InvoiceDetailItem
                label="Our Company"
                ourValue={"ENG İş Geliştirme ve Yatırım Ltd. Şti"}
                ocrValue={data.data.commissionDetail.ourCompany}
                isApproved={data.data.commissionDetail.isOurCompanyApproved}
                onAccept={() => {
                  onAccept("isOurCompanyApproved");
                }}
                onInputChanged={(value) => {
                  setValue("ourCompany", value);
                }}
              />
              <InvoiceDetailItem
                label="Amount"
                ourValue={data.data.commissionDetail.commission.finalAmount}
                ocrValue={data.data.commissionDetail.totalAmount}
                isApproved={data.data.commissionDetail.isTotalAmountApproved}
                onAccept={() => {
                  onAccept("isTotalAmountApproved");
                }}
                onInputChanged={(value) => {
                  setValue("totalAmount", value);
                }}
              />
              <InvoiceDetailItem
                label="IBAN"
                ourValue={
                  data.data.commissionDetail.commission.client == null
                    ? "Client Not Found Create New Client"
                    : data.data.commissionDetail.commission.client.IBAN
                }
                ocrValue={data.data.commissionDetail.iban}
                isApproved={data.data.commissionDetail.isIbanApproved}
                onAccept={() => {
                  onAccept("isIbanApproved");
                }}
                onInputChanged={(value) => {
                  setValue("iban", value);
                }}
              />
              <InvoiceDetailItem
                label="SWIFT"
                ourValue={
                  data.data.commissionDetail.commission.client == null
                    ? "Client Not Found Create New Client"
                    : data.data.commissionDetail.commission.client.SWIFT
                }
                ocrValue={data.data.commissionDetail.bic}
                isApproved={data.data.commissionDetail.isBicApproved}
                onAccept={() => {
                  onAccept("isBicApproved");
                }}
                onInputChanged={(formId, value) => {
                  setValue("bic", value);
                }}
              />
              <InvoiceDetailItem
                label="Invoice Date"
                ourValue={data.data.commissionDetail.commission.invoiceId}
                ocrValue={data.data.commissionDetail.invoiceDate}
                isApproved={data.data.commissionDetail.isInvoiceDateApproved}
                onAccept={() => {
                  onAccept("isInvoiceDateApproved");
                }}
                onInputChanged={(value) => {
                  setValue("invoiceDate", value);
                }}
              />
              <InvoiceDetailItem
                label="Bank Account Name"
                ourValue={
                  data.data.commissionDetail.commission.client == null
                    ? "Client Not Found Create New Client"
                    : data.data.commissionDetail.commission.client.billingName
                }
                ocrValue={data.data.commissionDetail.bankAccountHolder}
                isApproved={
                  data.data.commissionDetail.isBankAccountHolderApproved
                }
                onAccept={() => {
                  onAccept("isBankAccountHolderApproved");
                }}
                onInputChanged={(value) => {
                  setValue("bankAccountHolder", value);
                }}
              />

              <div className="mb-6">
                <label
                  className="block text-gray-700 text-sm font-bold mb-2"
                  htmlFor="name"
                >
                  Invoice ID
                </label>

                {data.data.commissionDetail.isHaveAllreadyInvoiceId && (
                  <div className="flex flex-row gap-2 bg-red-500 p-3 rounded-md mb-3 text-white">
                    <h1>Invoice ID is already used</h1>
                  </div>
                )}
                <input
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  id="invoiceId"
                  type="text"
                  placeholder="invoiceId"
                  // bind the input value to the client's role
                  defaultValue={data.data.commissionDetail.invoiceId}
                />
              </div>

              <div className="mb-6">
                <label
                  className="block text-gray-700 text-sm font-bold mb-2"
                  htmlFor="currency"
                >
                  Currency
                </label>

                <input
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  id="currency"
                  type="text"
                  placeholder="currency"
                  // bind the input value to the client's role
                  defaultValue={data.data.commissionDetail.currency}
                />
              </div>
              <div className="mb-6">
                <label
                  className="block text-gray-700 text-sm font-bold mb-2"
                  htmlFor="dueDate"
                >
                  Due Date
                </label>

                <input
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  id="dueDate"
                  type="text"
                  placeholder="dueDate"
                  // bind the input value to the client's role
                  defaultValue={data.data.commissionDetail.dueDate}
                />
              </div>

              <div className="mb-6">
                <label
                  className="block text-gray-700 text-sm font-bold mb-2"
                  htmlFor="description"
                >
                  Description
                </label>

                <textarea
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  id="description"
                  placeholder="description"
                  // bind the input value to the client's role
                  defaultValue={data.data.commissionDetail.description}
                />
              </div>
            </div>

            <div className="w-2/3">
              {data.data.commissionDetail.invoicePath &&
              data.data.commissionDetail.invoicePath.includes(".pdf") ? (
                <PDFViewerCustom
                  url={
                    "https://fantaslinkworld.blob.core.windows.net/invoicememberuploads/" +
                    data.data.commissionDetail.invoicePath.replace(
                      "public/uploads/paymentPdfs/",
                      ""
                    )
                  }
                ></PDFViewerCustom>
              ) : (
                <div className="flex flex-col gap-1">
                  <img
                    src={
                      "https://fantaslinkworld.blob.core.windows.net/invoicememberuploads/" +
                      data.data.commissionDetail.invoicePath.replace(
                        "public/uploads/paymentPdfs/",
                        ""
                      )
                    }
                  />
                </div>
              )}
            </div>
          </div>
          <Button type="submit" ref={formButtonRef} className="hidden">
            Save
          </Button>
        </div>
      </form>
    </AppLayout>
  );
}
